.App-logo {
  z-index: 2000;
  height: 70px;
  width: 70px;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #012169;
}

.App-log {
  z-index: 2001;
  height: 40px;
  width: 40px;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  bottom: 25px;
  right: 25px;
  color: white;
}

.App-chat {
  z-index: 2000;
  border-radius: 10px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 450px;
  overflow-y: auto;
  overflow-x: auto;
  position: fixed;
  bottom: 10px;
	right: 15px;
}

.App-level {
  padding-left: 10px;
  padding-top: 10px;
  display: inline-block;
  flex-wrap: wrap;
  align-items: center;
}

.App-topbar {
  overflow: hidden;
  position: fixed;
	border-bottom: 1px solid #c4c4c4;
	background: #bfd3ff;
	border-radius: 10px 10px 0 0;
	height: 60px;
  align-items: center;
  width: 450px;
}

.App-chat-area {
  overflow-y: auto;
  overflow-x: auto;
  padding-top: 60px;
  height: 560px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.App-minimize {
  margin-left: 15px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-top: 20px
}

.App-reboot {
  margin-left: 280px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-top: 20px
}

.App-usgovcert {
  height: 50px;
  vertical-align: top;
}

.App-bot {
  display: inline-block;
	width: 25px;
}

.App-para {
	display: inline-block;
	padding: 5px 15px;
	max-width: 90%;
	background: #0466ac;
  color: white;
	border-radius: 0 10px 10px 10px;
	margin: 5px;
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
}

.App-row {
  display: flex;
  align-items: flex-start;
}

.App-query {
  background-color: white;
  font-size: 13px;
  border-radius: 4px;
  border-color: #0466ac;
  border-width: 1px;
  font-family: Poppins;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 5px;
  text-align: left;
}

.App-query-clicked {
  background-color: #ccdbfc;
  font-size: 13px;
  border-radius: 4px;
  border-color: #0466ac;
  border-width: 1px;
  font-family: Poppins;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 5px;
  text-align: left;
}

.App-last-section {
  margin: 10px 70px;
  border-top-style: solid;
  border-top-color: #0466ac;
  border-top-width: thin;
}

.App-thumbs {
  margin-left: 180px;
  margin-bottom: 20px;
}

.App-like-dislike {
  color: #0466ac;
  height: 40px;
  width: 40px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  transition: transform .2s;
}

.App-like-dislike:hover {
  transform: scale(1.5);
}

.cardfeedback {
	border-radius: 5px;
	box-shadow: 0 4px 8px 0 rgb(0 0 0/ 20%);
	transition: 0.3s;
	padding: 10px;
	border: 3px solid white;
	margin-bottom: 20px;
  margin-left: 25px;
  width: 90%;
}

.cardfeedback:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	border: 1px solid #3c5091;
}

.email-field {
  font-size: 14px;
  width: 100%;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
  padding-left: 10px;
  font-family: Poppins;
}

.email-field:disabled {
  cursor: not-allowed;
}

.feedback-field {
  font-size: 14px;
  width: 100%;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 60px;
  margin-top: 5px;
  text-align: start;
  padding-left: 10px;
  padding-top: 10px;
  font-family: Poppins;
  resize: none;
}

.otp-field {
  font-size: 14px;
  width: 110%;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
  margin-top: 1px;
  margin-bottom: 5px;
  text-align: start;
  padding-left: 10px;
  font-family: Poppins;
}

.email-field:focus, .feedback-field:focus, .otp-field:focus {
  outline: none;
  border: 1px solid #0465ac;
}

.regenerate {
  margin-left: 50px;
  font-size: 14px;
  font-family: Lato;
  color: blue;
  text-decoration: underline;
}

.regenerate:hover {
  color: darkblue;
  cursor: pointer;
}

.feedback-submit {
  background-color: #bfd3ff;
  border: 2px solid #bfd3ff;
  border-radius: 5px;
  color: #3c5091;
  font-size: 16px;
  width: 100.9%;
  height: 40px;
  margin-top: 10px;
}

.feedback-submit:hover, .feedback-submit:active {
  background-color: #3c5091;
  border: 2px solid #3c5091;
  color: white;
}

.fields-alert {
  text-align: center;
  font-size: 13px;
  color: red;
  margin-bottom: 2px;
  margin-top: 2px;
  font-family: Lato;
}